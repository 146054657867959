import React, { useState }from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import ContactUsFormWrapper from './contactUs.style';
import Box from 'common/components/Box';


const ContactUs = () => {
  const Data = useStaticQuery(graphql`
    query {
      foodDeliveryJson {
        QUOTE_REQUEST_FORM {
          title
          list {
            label
            link
          }
        }
      }
    }
  `);
  const { title } = Data.foodDeliveryJson.QUOTE_REQUEST_FORM;
  
  const [customerName, setCustomerName] = useState("");
  const [emailId, setEmailId] = useState("");
  const [phoneNbr, setPhoneNbr] = useState("");
  const [orderNotes, setOrderNotes] = useState("");
  const [location, setLocation] = useState(1);

  const handleCustomerNameChange = (event) => {
    setCustomerName(event.target.value);
  }

  const handleEmailChange = (event) => {
    setEmailId(event.target.value);
  }

  const handlePhoneNbrChange = (event) => {
    setPhoneNbr(event.target.value);
  }

  const handleOrderNotesChange = (event) => {
    setOrderNotes(event.target.value);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log('creating quote request..');

    const CREATE_QUOTE_REQUEST_URL = "http://localhost:3000/nriyelp-svcs/quoteRequest";

    const response = await fetch(CREATE_QUOTE_REQUEST_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customerName, emailId, phoneNbr, orderNotes, location })
    });

    // Handle the server's response
    if (response.ok) {
      console.log('Quote Creation successful!');
      // Redirect the user to the home page or another page in your app
    } else {
      console.log('Quote creation failed!');
      // Display an error message to the user or handle the error in another way
    }
  };

  return (
    <ContactUsFormWrapper id="contactUsForm">
      <Container>
        <Heading as="h2" content="Contact Us" />
        <Box>
        <form onSubmit={handleSubmit} className="contactUsForm">
          <input 
            type='text' 
            value={customerName} 
            placeholder='Your name' 
            onChange={handleCustomerNameChange}
            required
          />
          <input 
            type='text' 
            value={emailId} 
            placeholder='Your email' 
            onChange={handleEmailChange}
            required
          />
          <textarea 
            value={orderNotes} 
            placeholder='Your Message' 
            onChange={handleOrderNotesChange}
            required
          />
        <button
          type="submit"
        >
         Submit
        </button>
    </form>
        </Box>
      </Container>
    </ContactUsFormWrapper>
  );
};

export default ContactUs;
